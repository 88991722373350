export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  SORT_BY: 'Sort by&nbsp;',

  PRODUCT_REPEATER_CLASS: 'row',

  SELECT_YOUR_VEHICLE: 'Select <span>Your Vehicle</span>',

  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'page-width',

  SEARCH_BOX_CLASS: 'search-header search search--focus',
  SEARCH_BOX_INPUT_CLASS: 'search-header__input search__input',
  SEARCH_BOX_BUTTON_CLASS: 'search-header__submit search__submit btn--link',
};
