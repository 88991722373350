const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern', 'wheel_offset'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/brands';

globalThis.Convermax.addToWishlist = (handle) => {
  // the function is partially copied from the theme

  const lsKey =
    window.getTheCookie('wishlist')?.indexOf(handle) === -1
      ? `${window.getTheCookie('wishlist')}__${handle}`
      : handle;

  window.setTheCookie('wishlist', lsKey, 14);

  /* eslint-disable no-undef */
  $(`.loadding-wishbutton-${handle}`).show();
  $(`.default-wishbutton-${handle}`).remove();
  window.setTimeout(function () {
    $(`.loadding-wishbutton-${handle}`).remove();
    $(`.added-wishbutton-${handle}`).show();
  }, 2000);
};

const updateCallback = () => {
  window.setupWishlistButtons();
};

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  defaultView: 'column-3',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: false,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results, body.template-collection .collection-grid-section',
      template: 'MainContent',
    },
    {
      name: 'FacetPanel',
      location: 'body.template-search .sidebar_content, body.template-collection .sidebar_content',
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'collection_sort': 'Featured',
        'relevance': 'Best Match',
        'title': 'Name: A-Z',
        'title:desc': 'Name: Z-A',
        'price': 'Price: low to high',
        'price:desc': 'Price: high to low',
        'published_at': 'Oldest to newest',
        'published_at:desc': 'Newest to oldest',
      },
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetPanel',
      disableCollapse: true,
      fields: wheelsFields,
      defaultFacetConfig: {
        type: 'selectFacet',
        name: 'selectFacet',
      },
    },
    {
      name: 'FacetBarTires',
      type: 'FacetPanel',
      disableCollapse: true,
      fields: tiresFields,
      defaultFacetConfig: {
        type: 'selectFacet',
        name: 'selectFacet',
      },
    },
    {
      name: 'SearchBox',
      location: '#search-container-full',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#_mobile_search',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: { insertBefore: '#_desktop_search', class: 'hidden-lg-down cm_garage__desktop' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { insertBefore: '#_mobile_cart', class: 'cm_garage__mobile' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: { insertBefore: '#MainContent', class: 'cm_vehicle-widget__header-container' },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      alwaysActive: true,
      columnBreakpoint: 800,
    },
    {
      name: 'VerifyFitment',
      location: { insertBefore: 'body.template-product .product-form__quantity' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTabDesktop',
      type: 'FitmentTable',
      location: {
        insertAfter: 'body.template-product #description-tab',
        wrapper: 'li',
        class: 'ishi-tab-item',
      },
      template: 'fitmentSearch/fitmentTableTabDesktop',
    },
    {
      name: 'FitmentTable',
      location: {
        insertAfter: 'body.template-product #tab-1',
        id: 'tab-fitment',
        class: 'ishi-tab-pane ishi-fade',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: 'body.template-list-collections .collection-list-section',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: {
        selector: 'body.template-page.brands #shopify-section-brands-template',
        class: 'cm_vehicle-categories__brands',
      },
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
